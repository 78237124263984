import React, { useState } from "react"
import { Box, Flex, Label, Title } from "../system"
import { Play, Pause } from "../Icons"

import ReactPlayer from "react-player"

const Video = ({ url, label, title, controls }) => {
  const [playing, setPlaying] = useState(false)

  return (
    <>
      {controls ? (
        <Box className="react-player">
          <ReactPlayer
            className="player"
            playing={playing}
            width="100%"
            height="100%"
            url={url}
            controls={controls}
          />
        </Box>
      ) : (
        <Box className="react-player">
          <ReactPlayer
            className="player"
            playing={playing}
            width="100%"
            height="100%"
            url={url}
          />
          <Flex
            bg={playing ? "rgba(0,0,0,0.0)" : "rgba(0,0,0,0.2)"}
            onClick={() => setPlaying(!playing)}
            position="absolute"
            top={0}
            left={0}
            height="100%"
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {!playing && (
              <>
                {label && (
                  <Label color="faded" display={["none", "inherit"]}>
                    {label}
                  </Label>
                )}
                {title && (
                  <Title
                    color="white"
                    mt={2}
                    fontSize={[5, 6]}
                    display={["none", "inherit"]}
                  >
                    {title}
                  </Title>
                )}
                <Flex
                  height={70}
                  width={70}
                  bg="white"
                  borderRadius={35}
                  onClick={() => {
                    setPlaying(!playing)
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {!playing ? <Play /> : <Pause />}
                </Flex>
              </>
            )}
          </Flex>
        </Box>
      )}
    </>
  )
}

export default Video
